import React, { useEffect, useRef } from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import AuthService from "../../service/auth_service";
import styles from "./login.module.css";
import { useNavigate } from "react-router-dom";
import dateFormat from "../../service/dateformat";

const Login = ({ authService, dataRepository }) => {
  const LOGIN_HISTORY_PATH = "testCompany/login_history/";
  const todayNow = new Date();
  const inputRef_id = useRef();
  const inputRef_pw = useRef();
  const navigate = useNavigate();

  const goToHome = (userId) => {
    const auto_login_hist = {
      id: Date.now(),
      activity: "Login_redirection",
      time: dateFormat(todayNow) || "",
      user: authService.firebaseAuth.lastNotifiedUid,
    };
    createOrUpdateData(auto_login_hist, LOGIN_HISTORY_PATH);
    console.log(userId);
    //       navigate('/home', {state:{id:userId}})
  };

  const onLogin = (event) => {
    event.preventDefault();
    authService
      //      .login(event.currentTarget.textContent)
      .login(inputRef_id.current.value, inputRef_pw.current.value)
      .then((userCredential) => {
        const user = userCredential.user;
        const login_hist = {
          id: Date.now(),
          activity: "Log in",
          time: dateFormat(todayNow) || "",
          user: authService.firebaseAuth.lastNotifiedUid,
        };
        createOrUpdateData(login_hist, LOGIN_HISTORY_PATH);
        //console.log(user.email)
      })
      .catch((err) => console.log(err.message));
  };

  const createOrUpdateData = (newData, path) => {
    // setData([...data, newData])
    dataRepository.saveData(newData, path + `${newData.id}`);
  };
  const check = () => {
    console.log(inputRef_id.current.value);
    console.log(inputRef_pw.current.value);
  };

  useEffect(() => {
    authService.onAuthChange((user) => {
      user && goToHome(user.uid);
    });
  });

  return (
    <div>
      <Header></Header>
      <div className={styles.index}>
        {/* <p className={styles.indexTitle}>News</p>
          <p className={styles.indexBodyText}> - To Be Updated</p>
          <p className={styles.indexBodyText}></p> */}

        <p className={styles.indexTitle}>Business Area</p>
        <p className={styles.indexBodyText}>
          <p className={styles.indexBodyTextTitle}>🦾 🤖 지능형 자동화</p>
          - 협동로봇 지능형 공장 자동화 시스템
          <br />
          - 공장 자동제어 및 감시제어 시스템
          <br />
          <p className={styles.indexTitle}>
            {/* <div className={styles.indexTitle}>소개영상</div> */}
            <div>------</div>
            <div className={styles.videoWrapper}>
              <div className={styles.videoContainer}>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/2SVdWwKqNXE"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </p>
          <br />
          <br />
          <p className={styles.indexBodyTextTitle}>🖥️ ⚡️ S/W & 에너지솔루션</p>
          - MES(Manufacturing Execution System)
          <br />
          - FEMS(Factory Energy Management System)
          <br />
          - 소프트웨어/ICT 기반 지능형 에너지 관리 및 절감 S/W
          <br />
          - 정류기 스마트 감시제어 솔루션
          <br />
          - 에너지설비(보일러, 냉동기, 히트펌프 등) 스마트 감시제어 시스템
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle}>🛠️ ⚙️ 엔지니어링 서비스</p>
          - 에너지설비(보일러, 냉동기, 히트펌프 등) 구축 설계 및 시공
          <br />- 정류기 구축 설계 및 시공
          <br />- 기타 전기 및 기계설비 구축 설계 및 시공
          <br />
          <br />
        </p>
        <p className={styles.indexTitle}>License</p>
        <p className={styles.indexBodyText}>
          🛠️ 기계설비공사업(Since 2020)
          <br />
          🌳 에너지절약전문기업(ESCO)(Since 2022)
          <br />
          ⚡️ 전기공사업(Since 2023)
          <br />
          <br />
        </p>
        <p className={styles.indexTitle}>Partnership</p>
        <p className={styles.indexBodyText}>
          - (주)레인보우로보틱스 공식 파트너사(Since 2023)
          <br />
          - (주)에스코, IGBT정류기 공급사 (Since 2021)
          <br />
          <br />
        </p>
        <p className={styles.indexTitle}>Certification/Patent</p>
        <p className={styles.indexBodyText}>
          - Method For Operation Scheduling Of Heater And Cooler Based on
          AI(특허등록, 2021)
          <br />
          - 멀티기능배관챔버 및 이를 이용한 에어벤트 시스템(특허등록, 2022)
          <br />- 뿌리기업 확인(Since 2022)
          <br />- 소프트웨어 사업자 (Since 2019)
          <br />
        </p>
        <p className={styles.indexTitle}>Members</p>
        <p className={styles.indexBodyText}>
          <p className={styles.indexBodyTextTitle}>김 창 원(CEO)</p>
          <br />
          <p className={styles.indexBodyTextTitle2}> EDUCATION</p>
          - 인천대학교 기계공학 학사(1992)
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle2}> EXPERIENCE</p>
          - 前 (주)삼천리이에스, 에너지사업부 팀장 (2002 ~ 2013)
          <br />
          - 現 (주)아라아이티이엔지 (2017 ~ 현재)
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle2}>CERTIFICATION</p>
          - 건축기계설비 기술사(2008)
          <br />
          - 가스 기사(1991)
          <br />
          - 일반기계 기사(1991)
          <br />
          - 건설기계설비 기사(1991)
          <br />
          - 공조냉동기계 기사(2004)
          <br />
          - 에너지관리 기사(2014)
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle2}>SKILLS</p>
          - 공장/빌딩 냉난방/공조
          <br />
          - 공장/빌딩 에너지 효율화 전략 수립
          <br />
          - 공장 자동제어/로봇 시스템 H/W 설계 및 시공
          <br />
          - 기계/전기 설비 설계/시공
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle2}>INTERESTS & HOBBIES</p>
          - 자동차
          <br />
          - 집 짓기
          <br />
          - 유튜브 보기
          <br />
          <br />
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle}>김 현 진(Team Lead)</p>
          <br />
          <p className={styles.indexBodyTextTitle2}>EDUCATION</p>
          - 연세대학교(서울) 전기전자공학 학사(2018)
          <br />
          - 연세대학교(서울) 컴퓨터공학 석사(2022)
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle2}>EXPERIENCE</p>
          - 前 Networking Research Lab, 연세대학교, Internship(2017)
          <br />
          - 前 (주)SK E&S, Internship(2019)
          <br />
          - 現 (주)아라아이티이엔지 (2019 ~ 현재)
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle2}>CERTIFICATION</p>
          - 전기공사기사(2018)
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle2}>SKILLS</p>
          - Front End 개발, React
          <br />
          - 산업현장 네트워크 & 통신 설계 및 현장 적용
          <br />
          - 협동로봇 엔지니어링
          <br />
          - 응용 AI
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle2}>INTERESTS & HOBBIES</p>
          - AI, 협동로봇
          <br />
          - 프로야구 SSG팬
          <br />
          - 비올라 연주(지금은 잘 안하기는 함)
          <br />
          <p className={styles.indexTitle}>
            {/* <div className={styles.indexTitle}>소개영상</div> */}
            <div>------</div>

            <div className={styles.videoWrapper}>
              <div className={styles.videoContainer}>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/K4luwV1diaM"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </p>
          <br />
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle}>
            안 재 성 (Senior Engineer)
          </p>
          <br />
          <p className={styles.indexBodyTextTitle2}>EDUCATION</p>
          - 인천대학교 산업경영공학 학사(2019)
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle2}>EXPERIENCE</p>
          - 前 (주)성우하이텍, 신차품질팀 사원(2020)
          <br />
          - 現 (주)아라아이티이엔지, Senior Engineer(2021 ~ 현재)
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle2}>CERTIFICATION</p>
          - 전기공사기사(2022)
          <br />
          - 품질경영기사(2018)
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle2}>SKILLS</p>
          - PLC, SCADA
          <br />
          - MES 설계
          <br />
          - 전기/제어/네트워크 배선 설계 및 시공
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle2}>INTERESTS & HOBBIES</p>
          - MES 개발
          <br />
          - 농구(인천 No.1 슈팅가드)
          <br />
          - 독서(관심분야임...ㅎ)
          <br />
          <br />
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle}>
            유 영 훈 (Junior Engineer)
          </p>
          <br />
          <p className={styles.indexBodyTextTitle2}>EDUCATION</p>
          - 인천대학교 수학과 학사(2021)
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle2}>EXPERIENCE</p>
          - 現 (주)아라아이티이엔지, Junior Engineer(2022~)
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle2}>CERTIFICATION</p>
          - 전기기사(2022)
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle2}>SKILLS</p>
          - PLC, SCADA
          <br />
          - 전기/제어/네트워크 배선 시공
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle2}>INTERESTS & HOBBIES</p>
          - 자동제어 시스템, 산업용 로봇, AI
          <br />
          - 강아지 산책(우리 체리)
          <br />
          - 수영(마스터)
          <br />
          - 메이플 랜드 유저
          <br />
          - 웹툰
          <br />
          <br />
          <br /> <br />
          <br />
          <p className={styles.indexBodyTextTitle}>
            김 지 건 (Junior Engineer)
          </p>
          <br />
          <p className={styles.indexBodyTextTitle2}>EDUCATION</p>
          - 한국교통대학교 전기공학 학사(2017)
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle2}>EXPERIENCE</p>
          - 現 (주)아라아이티이엔지, Junior Engineer(2024 ~ 현재)
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle2}>CERTIFICATION</p>
          - 전기기사(2023)
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle2}>SKILLS</p>
          - 기계설비/배관 시공
          <br />
          - 전기 및 자동제어 H/W 구축 시공
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle2}>INTERESTS & HOBBIES</p>
          - 유체역학에 관심이 생기고 있음
          <br />
          - 요리(양식 마스터)
          <br />
          - 오버워치
          <br />
          <p className={styles.indexTitle}>
            {/* <div className={styles.indexTitle}>소개영상</div> */}
            <div>------</div>

            <div className={styles.videoWrapper}>
              <div className={styles.videoContainer}>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/fhMNcpDwZuA"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </p>
          <br />
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle}>
            김 준 범 (Junior Engineer)
          </p>
          <br />
          <p className={styles.indexBodyTextTitle2}>EDUCATION</p>
          - 호서대학교 컴퓨터소프트웨어공학 학사(2019)
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle2}>EXPERIENCE</p>
          - 現 (주)아라아이티이엔지, Junior Engineer(2024 ~ 현재)
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle2}>CERTIFICATION</p>
          - 지게차운전기능사(2019)
          <br />
          - 굴삭기운전기능사(2020)
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle2}>SKILLS</p>
          - Python, JavaScript, C++, JAVA 다룸
          <br />
          - 전기/제어/네트워크 배선 시공
          <br />
          <br />
          <br />
          <p className={styles.indexBodyTextTitle2}>INTERESTS & HOBBIES</p>
          - 메타버스, 가상현실, AI - 유튜브 시청
          <br />
          - 리그오브레전드(칼바람 장인, 언랭유저라 티어는 없...)
          <br />
          <br />
          <br /> <br />
          <p className={styles.indexTitle}>Reference</p>
          - To Be Updated
          <br />
          <br />
          <br />
          {/* <p className={styles.indexBodyTextTitle2}>
              스마트공방 SW 임차료 월별단가 공시
            </p>
            - MES : 4,000,000원/월
            <br /> - 도금공정 감시제어/에너지관리 SW : 4,000,000원/월 */}
          <br />
        </p>
        {/* <div className={styles.indexBody}>
            <Grid container spacing={3} className={styles.gridIndex}>
              <Grid item xs={12}>
                area1
              </Grid>

               <Grid item xs={6} className={styles.indexTopWrap} >
                            <div className={styles.indexTop} id = 'top1'>
                                <p className={styles.top1Title}>Providing<br/><br/></p>
                                <p className={styles.top1Body}>&nbsp;- Software development service<br/></p>
                                <p className={styles.top1Body}>&nbsp;- Mechanical engineering service<br/></p>
                                <p className={styles.top1Body}>&nbsp;- Electrical engineering service</p>
                                <p className={styles.top1Body}>&nbsp;- Energy efficiency engineering service</p>
                                <p className={styles.top1Body}>&nbsp;- AI based engineering service<br/></p>
                                <p className={styles.moreBusiness}>
                                    <span
                                        onClick={
                                            ()=>{Router.push('/business-area').then(() => window.scrollTo(0, 0))}
                                        }
                                        styles={{cursor: 'pointer'}}>
                                        -자세히 보기-
                                    </span>
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={6} className={styles.indexTopWrap}>
                            <div className={styles.indexTop} id = 'top2' styles={{height:this.state.height * 0.534}}>
                                <Fade in={this.state.check} timeout={{enter: 400,exit:700}}>
                                    <img src={`/joker${this.state.pictureOne}.jpg`} className={styles.joker}/>
                                </Fade>
                                <Fade in={!this.state.check} timeout={{enter: 400,exit:700}}>
                                    <img src={`/joker${this.state.pictureTwo}.jpg`} className={styles.joker}/>
                                </Fade>
                                <svg className={styles.svgCircles} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="0" cy="50" r="5" fill={this.state.current_picture===1? '#1d80c4' : '#ffffff'} stroke={"#000000"} strokeWidth="1" styles={{cursor:'pointer'}}
                                            onClick={()=>{this.clickPicture(1)}}/>
                                    <circle cx="50" cy="50" r="5" fill={this.state.current_picture===2? '#1d80c4' : 'white'} stroke="black" strokeWidth="1" styles={{cursor:'pointer'}}
                                            onClick={()=>{this.clickPicture(2)}}/>
                                    <circle cx="100" cy="50" r="5" fill={this.state.current_picture===3? '#1d80c4' : 'white'} stroke="black" strokeWidth="1" styles={{cursor:'pointer'}}
                                            onClick={()=>{this.clickPicture(3)}}/>
                                </svg>
                            </div>
                        </Grid>
               <Grid item xs={12} className={styles.indexMidWrap}>
                            <div className={styles.indexMid}>
                                <div className={styles.midTitle}>News</div>
                                <div className={styles.midBody}>
                                    // NewsCard is custom Component, Look at src/components/NewsCard.js*
                                    <p styles={{fontSize: 16, minWidth:200,maxWidth:200}}>Our Recent Achievements</p>
                                    {this.state.cards.map((card)=>
                                        <NewsCard
                                            src={card.downloadUrl}
                                            postTitle={card.postTitle}
                                            postContent={card.postContent}
                                            onMore={()=>{this.onCardClick(card.id)}}
                                            onCardClick={()=>{this.onCardClick(card.id)}}
                                        />
                                    )}
                                </div>
                            </div>
                        </Grid>
              <Grid item xs={12} className={styles.indexBotWrap}>
                            <div className={styles.indexBottom} >
                            </div>
                        </Grid>
            </Grid>
          </div> */}
      </div>

      {/* <section className={styles.login_box}>
          <img className={styles.logo} src="/images/Logo_ILUDA_black.png" />
          <section className={styles.login_box_form}>

            <form className={styles.login_form} onSubmit={onLogin}>
              <ul>
                <h2>ID</h2>
                <input
                  ref={inputRef_id}
                  type="text"
                  className={styles.bar_id}
                  placeholder="이메일을 입력해주세요."
                />
              </ul>
              <ul>
                <h2>PW</h2>
                <input
                  ref={inputRef_pw}
                  type="password"
                  className={styles.bar_pw}
                  placeholder="비밀번호를 입력해주세요"
                />
              </ul>
              <ul>
                <button className={styles.btn_login}>로그인</button>
              </ul>
            </form>
          </section>
          <h3>Copyright 2023. ARAIT ENG Inc., all rights reserved</h3>
        </section> */}
      <Footer></Footer>
    </div>
  );
};

export default Login;
